@use "../../global-styles/palette" as c;
@use "./Slides/Slides";

.how-it-works-root {
    h3.h3-semi-bold {
        text-align: var(--title-text-alignment, center);
        color: var(--title-text-color, c.$dark-default);
    }

    p.copy-lp {
        color: var(--subTitle-text-color, c.$neutral-600);
        text-align: var(--subTitle-text-alignment, start);
        letter-spacing: 0;
        margin-top: 10px;
    }

    .how-it-works-root__contentWrapper {
        margin-top: 32px;

        .disclaimer-wrapper {
            margin-top: 40px;

            &__comment {
                margin-bottom: 10px;
                color: c.$dark-default;
                font-size: 14px;
                font-weight: 600;
                line-height: 25px;
            }

            .button-v2-wrapper {
                justify-content: start;

                .ghost-large-wbg {
                    border-color: c.$darker-gray-line-color;

                    &:hover {
                        background-color: transparent;
                        color: c.$dark-default;
                    }
                }
            }
        }
    }
}

.medium-view {
    .how-it-works-root {
        .how-it-works-root__contentWrapper {
            .disclaimer-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 40px;
                column-gap: 10px;

                &__comment {
                    margin: 0;
                }

                .button-v2-wrapper {
                    margin: 0 auto 0 0;
                }
            }
        }
    }
}

.large-view {
    .how-it-works-root {
        .how-it-works-root__contentWrapper {
            .disclaimer-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 40px;
                column-gap: 10px;

                &__comment {
                    margin: 0;
                }

                .button-v2-wrapper {
                    margin: 0 auto 0 0;
                }
            }
        }
    }
}