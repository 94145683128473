@use "../../global-styles/palette" as c;
@use "./StatSection/StatSection";
@use "./TradingViewSection/TradingViewSection";

.brokerWrapper__colouredBorder {
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, #EDEDED 13%, #5EE15A 30.75%, #EDEDED 47.43%);
    margin-top: 34px;
}

.stats-and-trust-pilot-root {
    &__statsWrapper {
        display: grid;
        gap: 20px;
        justify-content: center;
        grid-template-columns: repeat(auto-fit, 145px);
        margin-bottom: 32px;
    }

    &__brokerWrapper {
        .brokerWrapper__pipeSeparator {
            width: 1px;
            height: 23px;
            background-color: c.$tm-26-gray-line-separator-color;
            display: none;
        }

        .free-content-display {
            display: flex;
            justify-content: center;
            width: max-content;
            margin-inline: auto;
        }
    }

    .with-border-around-TP {
        .free-content-display {
            border: 1px solid c.$button-border-color;
            padding: 12px 16px;
            border-radius: 8px;
        }
    }
}

.medium-view {
    .stats-and-trust-pilot-root {
        &__statsWrapper {
            grid-template-columns: repeat(auto-fit, 110px);
        }

        &__brokerWrapper {
            display: flex;
            justify-content: center;
            align-items: center;

            .free-content-display {
                margin: 0;
            }

            .brokerWrapper__pipeSeparator {
                display: block;
                margin-inline: 20px 10px;
            }
        }

        .with-border-around-TP {
            .brokerWrapper__pipeSeparator {
                margin-inline: 20px;
            }
        }

    }
}

.large-view {
    .g-container {
        .brokerWrapper__colouredBorder {
            position: relative;
            left: -180px;
        }

        .stats-and-trust-pilot-root {
            display: flex;

            &__statsWrapper {
                justify-content: start;
                grid-template-columns: repeat(auto-fit, 105px);
                width: 100%;
                margin: 0;
            }

            &__brokerWrapper {
                display: flex;
                align-items: center;
                width: max-content;

                .brokerWrapper__pipeSeparator {
                    display: block;
                    margin-inline: 20px 10px;
                }
            }

            .with-border-around-TP {
                .brokerWrapper__pipeSeparator {
                    margin-inline: 20px;
                }
            }
        }
    }
}