@use "../../../global-styles/palette" as c;

.card-with-image-gradient-root {
    padding: 20px 0 20px 20px;
    display: flex;
    align-items: center;
    column-gap: 40px;
    border-radius: 20px;

    &__textWrapper {
        h3.h3-semi-bold-small {
            margin-bottom: 6px;
            color: c.$pure-white;
            line-height: 24px;
        }

        p.caption {
            color: c.$dark-20;
            line-height: 18px;
        }
    }

    &:nth-child(1) {
        background: radial-gradient(141.61% 141.42% at 0% 0%, #416B29 0%, #0E1D31 70%);
    }

    &:nth-child(2) {
        background: radial-gradient(141.61% 141.42% at 0% 0%, #4F657C 0%, #0E1D31 70%);
    }

    &:nth-child(3) {
        background: radial-gradient(141.61% 141.42% at 0% 0%, #6B5829 0%, #0E1D31 70%);
    }

    &:nth-child(4) {
        background: radial-gradient(141.61% 141.42% at 0% 0%, #1B4470 0%, #0E1D31 70%);
    }

    &:nth-child(5) {
        background: radial-gradient(141.61% 141.42% at 0% 0%, #322671 0%, #0E1D31 70%);
    }
}

.medium-view {
    .card-with-image-gradient-root {
        flex-direction: column;
        align-items: start;
        row-gap: 60px;
        padding: 32px;
        width: 100%;
        max-width: 150px;

        &__textWrapper {
            .h3-semi-bold-small {
                font-size: 22px;
                font-weight: 600;
                line-height: 26.4px;
            }

            p.caption {
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }
        }
    }
}

.large-view {
    .card-with-image-gradient-root {
        flex-direction: column;
        align-items: start;
        row-gap: 60px;
        padding: 32px;
        width: 100%;

        &__textWrapper {
            .h3-semi-bold-small {
                font-size: 22px;
                line-height: 26.4px;
            }

            p.caption {
                font-size: 14px;
                line-height: 21px;
            }
        }
    }
}