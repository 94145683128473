@use "../../global-styles/palette" as c;
@use "./FormInputs/FormInputs";
@use "./Overlay/Overlay";

.in-touch-form-root {
    background-image: var(--background-image-url);
    background-repeat: no-repeat;
    background-position: top center;

    .in-touch-form {
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;


        h2.h2-regular {
            font-weight: 700;
            font-size: 32px;
            line-height: 38.4px;
            letter-spacing: -0.64px;
            color: var(--title-text-color, c.$dark-gray-font-color);
            text-align: var(--title-text-alignment, center);
            max-width: 300px;
            margin-inline: auto;
        }

        p.in-touch-form-subTitle {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0;
            text-align: var(--subTitle-text-alignment, center);
            color: var(--subTitle-text-color, c.$dark-default);
            margin-block: 20px;
            max-width: 320px;
        }
    }
}

.medium-view {
    .in-touch-form-root {
        .in-touch-form {
            h2.h2-regular {
                font-size: 40px;
                line-height: 48px;
                letter-spacing: -0.8px;
                max-width: 350px;
            }

            p.in-touch-form-subTitle {
                max-width: 460px;
            }
        }
    }
}

.large-view {
    .in-touch-form-root {
        .in-touch-form {
            h2.h2-regular {
                font-size: 48px;
                letter-spacing: -0.96px;
                line-height: 57.6px;
                max-width: 420px;
            }

            p.in-touch-form-subTitle {
                max-width: 460px;
            }
        }
    }
}