@use "../../../global-styles/palette" as c;

.hero-banner-animation {
    --elements-margin-inline: 16px;
    --image-margin-top: 48px;

    & > .g-container {
        & > * {
            margin-inline: var(--elements-margin-inline);

            &.hero-banner-animation__bg-image {
                margin-inline: 0;
            }
        }
    }

    .g-container--new {
        .hero-banner-animation__bg-image {
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }

        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            margin-bottom: 8px;
            color: c.$pure-white;
        }

        h5 {
            color: c.$dark-20;
            margin-bottom: 32px;
        }
    }

    &.hero-banner-animation--with-capped-image {
        & > .g-container {
            padding-top: 32px;

            & > * {
                text-align: center;
            }
        }

        .hero-banner-animation__bg-image {
            margin-top: var(--image-margin-top);
        }
    }
}

.medium-view {
    .hero-banner-animation {
        --elements-margin-inline: 24px;
    }
}

.large-view {
    .hero-banner-animation {
        --elements-margin-inline: 32px;
        --image-margin-top: 0;

        .g-container--new {
            position: relative;
            z-index: 1;
            box-sizing: border-box;
            min-height: 450px;

            &::before {
                content: "";
                height: 100%;
                width: 230px;
                position: absolute;
                left: 0;
                background: linear-gradient(
                    270deg,
                    rgba(14, 29, 49, 0) 0%,
                    #0e1d31 100%
                );
            }

            .hero-banner-animation__bg-image {
                position: absolute;
                z-index: -1;
                width: 100%;
                max-width: 2000px;
                inset: 0;
                top: var(--space-from-top-image, -40px);

                img {
                    object-fit: cover;
                }
            }

            h1 {
                margin-top: 60px;
            }
        }

        &.hero-banner-animation--with-capped-image {
            & > .g-container {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                grid-auto-rows: auto;
                justify-items: center;
                align-items: end;
                padding-top: 0;

                & > * {
                    grid-column: 1;
                    text-align: center;
                }

                .hero-banner-animation__subtitle {
                    margin-bottom: 0;
                }

                .button-v2-wrapper {
                    grid-row: 3;
                    align-self: start;
                    z-index: 2;
                    margin-top: 32px;
                }

                .hero-banner-animation__bg-image {
                    position: static;
                    grid-row: 3;
                    z-index: 1;

                    img {
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        margin-inline: auto;
                    }
                }
            }
        }
    }
}
