@use "../../global-styles/palette" as c;
@use "./CardWithImageAndGradient/CardWithImageAndGradient";

.five-cards-title-text-root {
    .g-container {
        .five-cards-title-text-root__textWrapper {
            margin-bottom: 32px;

            div.h3-semi-bold {
                text-align: var(--title-text-alignment);
                margin-bottom: 20px;
                color: var(--title-text-color);
            }

            div.p-regular {
                text-align: var(--subTitle-text-alignment);
                color: var(--subTitle-text-color);
            }
        }

        .five-cards-title-text-root__cardsWrapper {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
        }
    }
}

.medium-view {
    .five-cards-title-text-root {
        .g-container {
            .five-cards-title-text-root__cardsWrapper {
                flex-direction: row;
                flex-wrap: wrap;
                gap: 12px;
            }
        }
    }
}

.large-view {
    .five-cards-title-text-root {
        .g-container {
            display: flex;
            flex-direction: column;
            align-items: center;


            .five-cards-title-text-root__textWrapper {
                display: flex;
                flex-direction: column;
                align-items: center;

                div.h3-semi-bold {
                    width: 100%;
                }

                div.p-regular {
                    max-width: 1100px;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 27px;
                }
            }

            .five-cards-title-text-root__cardsWrapper {
                column-gap: 12px;
                flex-direction: row;
                width: 100%;
                max-width: 1200px;
            }
        }
    }
}