@use "../../../global-styles/palette" as c;

.overlay-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 0;
    background-color: c.$dark-20;
    opacity: 0.8;

    &__modal-wrapper {
        color: c.$dark-default;
        font-size: 1em;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        min-width: 300px;
        align-items: center;
        background: c.$pure-white;
        padding: 20px 30px;
        border-radius: 20px;

        p.modal-message {
            margin-bottom: 10px;
            font-weight: bold;
        }

        .cta-large-wbg:hover {
            color: c.$pure-white;
        }
    }
}