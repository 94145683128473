.tradingView-section-root {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    margin-bottom: 20px;

    p.caption2-bold {
        line-height: 14px;
    }

    p.tradingView-section-root__marks {
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
    }
}

.medium-view {
    .tradingView-section-root {
        margin: 0;
    }
}

.large-view {
    .tradingView-section-root {
        margin: 0;
    }
}