@use "../../global-styles/palette" as c;
@use "./PlatformCard/PlatformCard";
@use "./AppWrapper/AppWrapper";

.platforms-download-root {
    &__textWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .textWrapper__title {
            color: var(--title-text-color, c.$dark-gray-font-color);
            text-align: var(--title-text-alignment, center);
            margin-bottom: 12px;
            font-size: 38px;
            font-weight: 700;
            line-height: 45.6px;
            letter-spacing: -0.76px;
        }

        p.copy-lp {
            color: var(--subTitle-text-color, c.$neutral-600);
            text-align: var(--subTitle-text-alignment, center);
            font-size: 18px;
        }
    }

    &__cardsWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 24px;
        margin-top: 40px;
    }
}

.medium-view {
    .platforms-download-root {
        &__textWrapper {
            .textWrapper__title {
                font-size: 48px;
                line-height: 57.6px;
                letter-spacing: -0.96px;
            }

            p.copy-lp {
                font-size: 18px;
            }
        }

        &__cardsWrapper {
            align-items: stretch;
        }
    }
}

.large-view {
    .platforms-download-root {
        &__textWrapper {
            .textWrapper__title {
                font-size: 58px;
                line-height: 69.6px;
                letter-spacing: -1.16px;
                max-width: 800px;
            }
        }

        &__cardsWrapper {
            flex-direction: row;
            column-gap: 24px;
            margin-top: 80px;
            justify-content: center;
        }
    }
}