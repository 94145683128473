@use "../../global-styles/palette" as c;

.rich-text-with-image {
    display: flex;
    align-items: center;
    gap: 64px;
    padding: 40px 0px;

    &__text-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    h1 {
        font-size: 38px;
        font-weight: 600;
        line-height: 48px;
        color: c.$dark-default;
    }
    h1, h2, .h1-v2dot6, h1.h1-v2dot6{
        font-weight: var(--title-font-weight);
    }

    &__text {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        max-width: 532px;
        color: c.$dark-80;
    }

    &__image {
        width: 532px;
    }
}

.large-view {
    .left {
        flex-direction: row-reverse;
        justify-content: center;
    }

    .right {
        flex-direction: row;
        justify-content: center;
    }

    .rich-text-with-image {
         h1, h2, .h1-v2dot6, h1.h1-v2dot6 {
            font-weight: var(--title-font-weight);
        }
        &__text-container {
            .rich-text-with-image__text {
                width: 480px;
            }
        }
    }
}

.medium-view {
    .rich-text-with-image {
        flex-direction: column;
        max-width: 600px;
        margin: 0px auto;
        gap: 32px;

        &__text-container {
            h1 {
                font-size: 30px;
                line-height: 38px;
                text-align: var(--text-alignment);
            }
        }

        &__text {
            max-width: 600px;
            text-align: var(--text-alignment);
        }

        &__image {
            width: 600px;
        }
    }
}

.small-view {
    .rich-text-with-image {
        flex-direction: column;
        max-width: 327px;
        margin: 0px auto;
        gap: 32px;

        &__text-container {
            h1 {
                font-size: 26px;
                line-height: 32px;
                text-align: var(--text-alignment);
            }
        }
    
        &__text {
            max-width: 327px;
            font-size: 16px;
            line-height: 24px;
            text-align: var(--text-alignment);
        }
    
        &__image {
            width: 327px;
        }
    }

    
}