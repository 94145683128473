@use "../../../global-styles/palette" as c;

.slides-root {
    min-height: 155px;

    p.slide-number {
        color: c.$dark-default;
        font-size: 22.917px;
        font-weight: 700;
        line-height: 25px;
        border-radius: 50%;
        border: 2px solid c.$dark-default;
        width: max-content;
        padding: 12.5px 20px;
        margin-bottom: 17.5px;

        &::after {
            content: "";
            height: 2px;
            width: 70%;
            display: block;
            position: absolute;
            inset: 0;
            top: 18%;
            margin-inline-start: 75px;
            background-color: c.$dark-10;
        }
    }

    h5.h5-semi-bold {
        line-height: 27px;
        color: c.$dark-80;
    }
}

.medium-view {
    .slides-root {
        p.slide-number {
            &::after {
                margin-inline-start: 70px;
            }
        }
    }
}

.large-view {
    .slides-root {
        p.slide-number {
            &::after {
                width: 65%;
            }
        }

        h5.h5-semi-bold {
            max-width: 250px;
        }
    }
}