@use "../../global-styles/palette" as c;
@use "../../global-styles/typography";

.mega-heading {
    display: flex;
    flex-direction: column;
    max-width: 960px;
    margin: 0 auto;

    &__title {
        align-self: var(--alignment);
        text-align: var(--alignment);

        &--style {
            &--solid {
                color: var(--color1, #{c.$dark-default});
            }

            &--gradient-linear {
                background: linear-gradient(270deg, var(--color1) 0%, var(--color2) 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }

    h1#{&}__title {
        @extend .h1-v2dot6;
    }

    h2#{&}__title {
        @extend .h2-v2dot6;
    }

    h3#{&}__title {
        @extend .h3-v2dot6;
    }

    h4#{&}__title {
        @extend .h4-v2dot6;
    }

    h5#{&}__title {
        @extend .h5-v2dot6;
    }

    h6#{&}__title {
        @extend .h6-v2dot6;
    }

    &__title#{&}__subtitle:not(:empty) {
        margin-top: 6px;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.5;
    }
}

.medium-view {
    .mega-heading {
        h1#{&}__title {
            @extend .h1-v2dot6;
        }
    
        h2#{&}__title {
            @extend .h2-v2dot6;
        }
    
        h3#{&}__title {
            @extend .h3-v2dot6;
        }
    
        h4#{&}__title {
            @extend .h4-v2dot6;
        }
    
        h5#{&}__title {
            @extend .h5-v2dot6;
        }
    
        h6#{&}__title {
            @extend .h6-v2dot6;
        }
    
        .mega-heading__title.mega-heading__subtitle:not(:empty) {
            font-size: 18px;
            font-weight: 500;
            line-height: 1.5;
        }
    }
}

.large-view {
    .mega-heading {
        h1#{&}__title {
            @extend .h1-v2dot6;
        }
    
        h2#{&}__title {
            @extend .h2-v2dot6;
        }
    
        h3#{&}__title {
            @extend .h3-v2dot6;
        }
    
        h4#{&}__title {
            @extend .h4-v2dot6;
        }
    
        h5#{&}__title {
            @extend .h5-v2dot6;
        }
    
        h6#{&}__title {
            @extend .h6-v2dot6;
        }
    
        .mega-heading__title.mega-heading__subtitle:not(:empty) {
            font-size: 18px;
            font-weight: 500;
            line-height: 1.5;
        }
    }
}