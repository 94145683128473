@use "../../../global-styles/palette" as c;

.app-wrapper-root {
    display: flex;
    justify-content: center;

    .commonStyle {
        border-radius: 6px;
        border: 1px solid c.$dark-10;
        background-color: c.$pure-white;
        padding: 10px 12px 5px 12px;
        cursor: pointer;

        &:first-child {
            margin-inline-end: 12px;
        }
    }

    &__left-image-container {
        @extend .commonStyle;
    }

    &__right-image-container {
        @extend .commonStyle;
    }
}