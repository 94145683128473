@use "../../../global-styles/palette" as c;

.platform-card_wrapper {
    border: 1px solid c.$tm-card-border-lightblue;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 16px;
    row-gap: 32px;
    border-radius: 16px;
    background-color: c.$neutral-accent-light-grey;

    &__image_and_text_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .image_and_text_wrapper__imageWrapper {
            max-width: 64px;
            max-height: 64px;
            border-radius: 10.667px;
            box-shadow: 0px 2.667px 10.667px 0px rgba(0, 0, 0, 0.10);
            background-color: c.$pure-white;
            padding: 16px;
            margin-bottom: 32px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .image_and_text_wrapper__textWrapper {
            display: flex;
            flex-direction: column;
            align-items: center;

            h4.h4-lp {
                line-height: 32px;
                margin-bottom: 8px;
            }

            p.caption2-regular {
                color: c.$dark-80;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 21px;
                width: 94%;
            }
        }
    }

    &__button_and_apps_wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .button-v2-wrapper.flex {
            margin-bottom: 32px;
            width: 100%;

            a {
                width: 100%;
            }
        }
    }
}

.medium-view {
    .platform-card_wrapper {
        padding: 32px;
        align-items: start;

        &__image_and_text_wrapper {

            flex-direction: row;
            align-items: flex-start;
            column-gap: 20px;

            .image_and_text_wrapper__imageWrapper {
                margin-bottom: unset;
            }

            .image_and_text_wrapper__textWrapper {
                align-items: flex-start;

                p.caption2-regular {
                    text-align: start;
                }
            }
        }

        &__button_and_apps_wrapper {
            flex-direction: row;
            column-gap: 32px;

            .button-v2-wrapper.flex {
                margin-bottom: unset;
                max-width: 292px;
                margin-inline: 0;
            }
        }
    }
}

.large-view {
    .platform-card_wrapper {
        &__image_and_text_wrapper {
            .image_and_text_wrapper__textWrapper {
                h4.h4-lp {
                    font-size: 24px;
                }

                p.caption2-regular {
                    width: 100%;
                    max-width: 300px;
                }
            }
        }
    }
}