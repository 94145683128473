@use "../../global-styles/palette" as c;

.rich-text-with-image-uplift {
    --image-border-radius: 20px;

    display: flex;
    align-items: center;
    gap: 64px;
    padding: 40px 0px;

    &__text-container {
        display: flex;
        flex-direction: column;
        gap: 16px;

        h1, h2, .h1-v2dot6, h1.h1-v2dot6{
            font-weight: var(--title-font-weight);
        }
    }

    &__items-wrapper {
        display: flex;
        flex-direction: row;
        gap: 30px;
        margin-top: 24px;
    }

    &__item1, &__item2, &__item3 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        min-width: 152px;
    }

    &__item-title {
        color: c.$dark-default;
    }

    &__item-subtitle {
        color: #172033;
    }

    &__text {
        font-style: normal;
        color: c.$dark-default;
    }

    &__image {
        max-width: 100%;
        border-radius: var(--image-border-radius);
    }
}

.large-view {
    .rich-text-with-image-uplift {
        &.left {
            flex-direction: row-reverse;
            justify-content: space-between;

            .rich-text-with-image-uplift__image {
                --image-margin-inline: 0 auto;
            }
        }
    
        &.right {
            flex-direction: row;
            justify-content: space-between;

            .rich-text-with-image-uplift__image {
                --image-margin-inline: auto 0;
            }
        }

        &__text-container {
            flex: 1 1 55%;

            h1, h2, .h1-v2dot6, h1.h1-v2dot6, h2.h2-v2dot6{
                font-weight: var(--title-font-weight);
            }

            h2 {
                max-width: 571px;
            }
        }

        &__image-container {
            flex: 1 1 45%;
        }

        &__item-title {
            font-size: 48px;
            font-weight: 800;
            line-height: 100%;
        }
    
        &__item-subtitle {
            font-size: 18px;
            font-weight: 400;
            line-height: 100%;
        }

        &__text {
            max-width: 720px;
            font-size: 20px;
            font-weight: 500;
            line-height: 150%;
        }

        &__image-container {
            overflow: hidden;
        }

        &__image-size-guard {
            width: 600px;
            max-width: 100%;
            max-height: 400px;
            overflow: hidden;
            border-radius: var(--image-border-radius);
        }

        &__image {
            display: block;
            max-width: 100%;
            max-height: 400px;
            object-fit: contain;
            margin-inline: var(--image-margin-inline);
        }

    }
}

.medium-view {
    .rich-text-with-image-uplift {
        padding: 0 16px;
        justify-content: space-between;

        margin: 0px auto;
        gap: 50px;

        &__text-container {
            h2 {
                text-align: var(--text-alignment);
                font-weight: var(--title-font-weight);
            }
        }

        &__item1, &__item2, &__item3 {
            min-width: 134px;
        }

        &__item-title {
            font-size: 48px;
            font-weight: 800;
            line-height: 100%;
        }
    
        &__item-subtitle {
            font-size: 18px;
            font-weight: 400;
            line-height: 100%;
        }

        &__text {
            max-width: 447px;
            text-align: var(--text-alignment);
            font-size: 18px;
            font-weight: 500;
            line-height: 150%;
        }

        &__image {
            max-width: 100%;
        }
    }
}

@media only screen and (max-width: 768px){
    .medium-view {
        .rich-text-with-image-uplift {
            flex-direction: column;

            &__text-container {
                .h2-v2dot6 {
                    text-align: var(--text-alignment);
                    font-weight: var(--title-font-weight);
                }
            }

            &__text {
                max-width: 664px;
            }

            &__image {
                max-width: 664px;
                max-height: 340px;
            }

            &__item1, &__item2, &__item3 {
                min-width: 152px;
            }
        }
    }
}

.small-view {

    .rich-text-with-image-uplift-main {
        .g-container {
            margin: 0 20px;
        }

        .rich-text-with-image-uplift {
            flex-direction: column;
            max-width: 327px;
            margin: 0px auto;
            gap: 32px;
            padding: 0;

            &__text-container {
                align-items: center;

                .h2-v2dot6 {
                    font-weight: var(--title-font-weight);
                    line-height: 120%;
                    max-width: 335px;
                }
            }
        
            &__text {
                max-width: 65%;
                min-width: 335px;
                font-size: 18px;
                line-height: 150%;
                font-weight: 500;
                text-align: var(--text-alignment);
            }

            &__items-wrapper {
                width: 100%;
                flex-direction: column;
                margin-bottom: 18px;
            }

            &__item1, &__item2, &__item3 {
                min-width: unset;
                flex-direction: row;
                align-items: center;
            }

            &__item-title {
                font-size: 48px;
                font-weight: var(--title-font-weight);
                line-height: 100%;
            }
        
            &__item-subtitle {
                font-size: 18px;
                font-weight: 400;
                line-height: 100%;
            }
        
            &__image {
                width: 340px;
                max-width: 100%;
                max-height: 340px;
            }
        }
    }
}