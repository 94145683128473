@use "../../../global-styles/palette" as c;

.form-inputs-root {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    align-items: center;
    width: 100%;
    max-width: 350px;

    span {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        input {
            color: c.$neutral-500;
            border-radius: 8px;
            outline: none;
            border: 1px solid c.$neutral-border-color;
            background: c.$pure-white;
            height: 40px;
            padding-inline-start: 16px;
            width: 100%;
        }

        input:last-of-type {
            margin-bottom: 4px;
        }
    }

    button {
        width: max-content;
        padding: 15px 28px;
        border-radius: 38px;
        background: c.$tm-green-default;
        border: transparent;
        color: c.$dark-default;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;
        font-family: Figtree, sans-serif;
    }

    p.errorLabel {
        color: c.$error;
        width: max-content;
        padding-inline-start: 10px;
        font-size: small;
        font-weight: bold;
    }
}

.medium-view {
    .form-inputs-root {
        max-width: 500px;
    }
}

.large-view {
    .form-inputs-root {
        max-width: 500px;
    }
}