@use "../../../global-styles/palette" as c;
@use "./SmallAwardCard/SmallAwardCard";
@use "./MobileSmallAwardCard/MobileSmallAwardCard";

.small-award-cards-deck-root {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    &__title {
        color: c.$dark-default;
        text-align: var(--title-alignment);
    }

    .awards-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 60px;
        flex-wrap: wrap;
        gap: 40px 15px;
        max-width: 475px;
    }
}

.medium-view {
    .small-award-cards-deck-root {
        &__title {
            width: 93%;
        }

        .awards-wrapper {
            max-width: unset;
        }
    }
}

.large-view {
    .small-award-cards-deck-root {
        &__title {
            max-width: 900px;
        }

        .awards-wrapper {
            gap: 40px;
            max-width: 1200px;
        }
    }
}