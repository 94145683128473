.stat-section {
    p.stat-section__statTitle {
        text-align: center;
        font-size: 25px;
        font-weight: 700;
        line-height: 30px;
        margin-bottom: 4px;
    }

    .stat-section__statSubTitle {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
    }
}

.large-view {
    .stat-section {
        p.stat-section__statTitle {
            text-align: start;
        }

        .stat-section__statSubTitle {
            text-align: start;
        }
    }
}